.crash-game-container {
  text-align: center;
}

.multiplier-display {
  font-size: 2em;
  margin: 20px 0;
}

.line-container {
  width: 100px;
  height: 300px;
  border: 2px solid #000;
  margin: 20px auto;
  position: relative;
  overflow: hidden;
}

.line {
  width: 100%;
  height: 100%;
  background-color: green;
  transform-origin: bottom;
  transform: scaleY(1);
  transition: transform 0.1s linear;
}

.controls {
  margin-top: 20px;
}

.crash-info {
  margin-top: 20px;
  font-size: 1em;
}