/* src/components/RegisterPage.css */
.register-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .form-box {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-box h2 {
    margin-bottom: 20px;
  }
  
  .form-box input {
    width: 300px;
    padding: 12px;
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-box button {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    background-color: #008CBA;
    color: white;
    font-size: 16px;
  }
  
  .form-box button:hover {
    background-color: #007bb5;
  }
  
  .error-message {
    color: red;
  }
  
  .link-button {
    background: none;
    border: none;
    color: #0066cc;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font-size: 14px;
  }
  
  .link-button:hover {
    color: #004999;
  }
  