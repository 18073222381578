.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #1e1e1e, #3a3a3a);
  color: white;
  padding: 10px 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-left {
  flex: 1;
}

.animated-logo {
  display: flex;
  justify-content: center;
  gap: 4px;
  font-size: 24px;
  font-weight: bold;
  color: #f0c040;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  text-decoration: none;
}

.animated-logo .letter {
  display: inline-block;
  width: 20px;
  text-align: center;
  animation: spin 0.1s linear infinite;
}

.animated-logo .colon {
  margin: 0 5px;
  color: #fff;
}

@keyframes spin {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.navbar-center {
  flex: 2;
  display: flex;
  justify-content: center;
  gap: 30px;
}

.navbar-item {
  font-size: 16px;
}

.navbar-label {
  font-weight: bold;
  color: #f0c040;
}

.navbar-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.navbar-button {
  background: linear-gradient(90deg, #ff414d, #ff5757);
  border: none;
  color: white;
  padding: 8px 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.navbar-button:hover {
  background: linear-gradient(90deg, #ff5757, #ff414d);
}

.navbar-button.logout {
  background: linear-gradient(90deg, #333333, #555555);
}

.navbar-button.logout:hover {
  background: linear-gradient(90deg, #555555, #333333);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.popup-buttons button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.popup-buttons button:hover {
  background-color: #45a049;
}

.close-button {
  margin-top: 20px;
  background-color: #d9534f;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #c9302c;
}
