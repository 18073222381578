.profile-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

h1, h2 {
  text-align: center;
  color: #4caf50;
  margin-bottom: 20px;
}

.trade-link-section {
  margin-bottom: 30px;
  text-align: center;
}

.trade-link-section input {
  width: 70%;
  padding: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.trade-link-section button {
  padding: 10px 15px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.trade-link-section button:hover {
  background-color: #45a049;
}

.success-message {
  color: green;
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.inventory-section {
  margin: 40px auto; /* Odstup shora a automatické zarovnání na střed */
  padding: 0 10px; /* Přidá odsazení od okrajů stránky */
  width: 100%; /* Zajištění plné šířky sekce */
  box-sizing: border-box; /* Zajištění, že padding se nezapočítá do šířky */
}

.inventory-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Dynamický počet sloupců */
  gap: 20px; /* Mezera mezi položkami */
}

.inventory-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  height: 150px;
  border-radius: 8px;
  color: #888;
  font-size: 14px;
}

@media (max-width: 600px) {
  .trade-link-section input {
      width: 100%;
      margin-bottom: 10px;
  }

  .trade-link-section button {
      width: 100%;
  }
}

.inventory-item {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.inventory-item:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px);
}

.inventory-item.selected {
  background-color: #d4edda;
  border-color: #28a745;
}

.inventory-item img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.inventory-item p {
  margin: 5px 0;
  font-size: 14px;
  color: #555;
}

/* Styl pro tlačítko "Načíst více" */
.load-more {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
}

.load-more:hover {
  background-color: #45a049;
}

/* Styl pro deaktivované tlačítko "Načíst více" */
.load-more:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.trade-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4CAF50; /* Zelená */
  color: white;
  padding: 15px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.trade-button:hover {
  background-color: #45a049; /* Tmavší zelená */
}

.trade-button:disabled {
  background-color: #ccc; /* Šedá */
  cursor: not-allowed;
}