.roulette-container {
  display: flex;
  flex-direction: column; /* Vertikální zarovnání */
  align-items: center; /* Horizontální zarovnání na střed */
  padding: 20px;
}

.start-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px; /* Přidání mezery nad tlačítkem */
}

.result-display {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #000;
  padding: 10px;
  display: inline-block;
}

.betting-controls {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px; /* Přidání mezery nad sázkovými ovládacími prvky */
}

.betting-column {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}

.bet-button {
  margin: 5px 0;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px; /* Menší zaoblení na okrajích */
  border: 1px solid #000;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.bet-button.zero {
  height: 150px; /* Výška jako tři tlačítka */
  background-color: green; /* Zelená barva */
  color: #fff;
}

/* Barvy jednotlivých čísel */
.bet-button.one { background-color: #f00; color: #fff; } /* 1 */
.bet-button.two { background-color: #000; color: #fff; } /* 2 */
.bet-button.three { background-color: #f00; color: #fff; } /* 3 */
.bet-button.four { background-color: #000; color: #fff; } /* 4 */
.bet-button.five { background-color: #f00; color: #fff; } /* 5 */
.bet-button.six { background-color: #000; color: #fff; } /* 6 */
.bet-button.seven { background-color: #f00; color: #fff; } /* 7 */
.bet-button.eight { background-color: #000; color: #fff; } /* 8 */
.bet-button.nine { background-color: #f00; color: #fff; } /* 9 */
.bet-button.ten { background-color: #000; color: #fff; } /* 10 */
.bet-button.eleven { background-color: #000; color: #fff; } /* 11 */
.bet-button.twelve { background-color: #f00; color: #fff; } /* 12 */
.bet-button.thirteen { background-color: #000; color: #fff; } /* 13 */
.bet-button.fourteen { background-color: #f00; color: #fff; } /* 14 */
.bet-button.fifteen { background-color: #000; color: #fff; } /* 15 */
.bet-button.sixteen { background-color: #f00; color: #fff; } /* 16 */
.bet-button.seventeen { background-color: #000; color: #fff; } /* 17 */
.bet-button.eighteen { background-color: #f00; color: #fff; } /* 18 */
.bet-button.nineteen { background-color: #f00; color: #fff; } /* 19 */
.bet-button.twenty { background-color: #000; color: #fff; } /* 20 */
.bet-button.twenty-one { background-color: #f00; color: #fff; } /* 21 */
.bet-button.twenty-two { background-color: #000; color: #fff; } /* 22 */
.bet-button.twenty-three { background-color: #f00; color: #fff; } /* 23 */
.bet-button.twenty-four { background-color: #000; color: #fff; } /* 24 */
.bet-button.twenty-five { background-color: #f00; color: #fff; } /* 25 */
.bet-button.twenty-six { background-color: #000; color: #fff; } /* 26 */
.bet-button.twenty-seven { background-color: #f00; color: #fff; } /* 27 */
.bet-button.twenty-eight { background-color: #000; color: #fff; } /* 28 */
.bet-button.twenty-nine { background-color: #000; color: #fff; } /* 29 */
.bet-button.thirty { background-color: #f00; color: #fff; } /* 30 */
.bet-button.thirty-one { background-color: #000; color: #fff; } /* 31 */
.bet-button.thirty-two { background-color: #f00; color: #fff; } /* 32 */
.bet-button.thirty-three { background-color: #000; color: #fff; } /* 33 */
.bet-button.thirty-four { background-color: #f00; color: #fff; } /* 34 */
.bet-button.thirty-five { background-color: #000; color: #fff; } /* 35 */
.bet-button.thirty-six { background-color: #f00; color: #fff; } /* 36 */