.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.form-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vh;
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-box h2 {
  margin-bottom: 20px;
}

.animated-title {
  display: flex;
  justify-content: center;
  gap: 4px;
  font-size: 36px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #333;
  overflow: hidden;
}

.letter {
  display: inline-block;
  width: 30px; /* Stejná šířka pro všechna písmena */
  text-align: center; /* Zarovnání písmen na střed */
  animation: spin 0.1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
}

.form-box input {
  width: 300px;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-box button {
  display: inline-block;
  width: 250px;
  padding: 12px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
}

.form-box button:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
}

.link-button {
  background: none;
  border: none;
  color: #0066cc;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  display: block;
  margin-top: 4px;
  width: 250px;
}

.link-button:hover {
  color: #004999;
}

.register-prompt {
  text-align: center;
}

.register-prompt p {
  margin: 5px;
}

.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 10px;
  transition: transform 0.2s;
}

.profile-image:hover {
  transform: scale(1.1);
}
