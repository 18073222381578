/* Globální nastavení kontejneru */
.game-container {
  position: relative;
  overflow: hidden;
}

/* Top bar pro boxy s CS:GO skiny */
.top-bar {
  display: flex;
  align-items: center;
  position: relative;
  height: 100px;
  background-color: #ccc;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  cursor: grab;
}

.top-bar:active {
  cursor: grabbing;
}

/* Boxy pro skiny */
.skin-box {
  position: absolute;
  width: 140px;
  height: 80px;
  background-color: #444;
  color: white;
  text-align: center;
  line-height: 80px;
  border-radius: 8px;
  margin-right: 10px;
  transition: transform 0.02s linear; /* Rychlá odezva na pohyb myši */
  user-select: none; /* Zamezení označování textu */
}

.skin-box:hover {
  background-color: #666;
}

/* Kontejner pro horizontální rozložení sekcí */
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

/* Side menu */
.side-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.side-menu-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 30px 15px;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease;
  margin-bottom: 10px;
  position: relative;
}

.side-menu-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 1;
}

.side-menu-button:hover {
  background-color: #2980b9;  /* Změna barvy tlačítka při najetí myši */
}

/* Obecné nastavení sekce */
.section {
  margin-bottom: 20px; /* Mezera mezi sekcemi */
  text-align: center; /* Zarovnání nadpisů na střed */
}

.section h2 {
  margin-bottom: 10px; /* Mezera mezi nadpisem a tlačítky */
  font-size: 30px;
  color: #444;
}

/* Rozložení tlačítek */
.pvp-section,
.rng-section {
  display: flex; /* Flexbox pro horizontální rozložení tlačítek */
  justify-content: center; /* Zarovnání na střed */
  gap: 15px; /* Mezera mezi tlačítky */
  border-style: none;
  min-height: 500px;
}

/* Styl tlačítek */
.game-box {
  border-style: none;
  background-color: #2ecc71;
  text-align: center;
  justify-content: center;
  padding: 15px 20px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 24px;
  min-width: 120px; /* Šířka tlačítek */
  min-height: 250px;  /* Výška tlačítek */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Přidání transformace */
}

.game-box:hover {
  background-color: #27ae60; /* Změna barvy tlačítka při najetí myši */
  transform: scale(1.15); /* Zvětšení tlačítka při najetí myši */
}

/* Friend list */
.friend-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.friend-list-button {
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 30px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s ease;
}

.friend-list-button:hover {
  background-color: #27ae60;
}