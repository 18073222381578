/* src/components/PaymentForm.css */
form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 300px;
  }
  
  label {
    font-weight: bold;
  }
  
  input,
  select,
  button {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    background-color: #28a745;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #218838;
  }
  